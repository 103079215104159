import { styled } from "goober";

import { IS_APP } from "@/__main__/constants.mjs";
import getOSType from "@/util/get-os-type.mjs";

export const Container = styled("div")`
  display: flex;
  background: var(--nav-bg);
  height: var(--title-bar-height, --sp-10);

  justify-content: space-between;
  &[data-draggable="true"] {
    -webkit-app-region: drag;
  }

  @container app (inline-size <= 1200px) {
    padding-inline: ${IS_APP ? "unset;" : "var(--sp-20) var(--sp-30);"};
  }

  &.mac {
    flex-direction: row-reverse;
    .logo-container {
      justify-content: flex-end;
    }
  }

  .spacer {
    width: calc(var(--sp-9) * 3);
  }

  .logo-container {
    display: flex;
    width: calc(var(--sp-9) * 3);
    padding-inline: var(--sp-4);
  }

  .blitz-logo {
    width: 2.625rem;
    height: 100%;
    opacity: 0.75;
  }

  .contents {
    flex: 1 1 0;
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--sp-3);
    min-width: 0;
    max-width: calc(var(--header-container, var(--sp-container)));
    color: var(--shade2);
  }

  .game,
  .search {
    position: relative;

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 50%;
      translate: 0 -50%;
      width: 1px;
      height: var(--sp-4);
      background: hsla(220, 5%, 52%, 0.25);
    }
  }
  .search {
    color: var(--shade2);
    padding-right: var(--sp-3);
  }
  .game {
    position: relative;
    padding-right: var(--sp-1);
  }

  .game img {
    font-weight: 600;
    color: var(--green);

    height: 1.5em;
    width: auto;
  }
  .game::before {
    content: "";
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 50%;
    height: 8px;
    width: 8px;
    transform: translate(0, -10%);
    background-color: var(--green);
  }

  button {
    cursor: pointer;
    svg {
      flex: 0 0 var(--sp-5);
      width: var(--sp-5);
      aspect-ratio: 1;
    }
  }

  .address-bar {
    position: relative;
    flex: 1 1 0;
    min-width: 0;
    /* effective minimum draggable width. on windows gib a littttle extra for right controls */
    /* ${getOSType() === "win32"
      ? "margin-right: calc(max(0px, (var(--sp-1) * 120) - (100vw - 100%)));"
      : ""} */

    button.copy {
      position: absolute;
      display: grid;
      place-items: center;
      right: var(--sp-1);
      top: 0;
      bottom: 0;
      margin-inline-start: auto;
      border-radius: 50%;
      aspect-ratio: 1;
      background: transparent;
      transition: background var(--transition);

      svg {
        fill: var(--shade1-75);
      }

      &:hover {
        svg {
          fill: var(--shade0);
        }
      }
    }
  }
  .address-bar-btn {
    --address-bar-height: var(--sp-8);
    -webkit-app-region: no-drag;
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--sp-2_5);
    width: 100%;
    height: var(--address-bar-height);
    padding: var(--sp-1_5) var(--sp-2_5);
    font-size: var(--sp-3_5e);
    border-radius: 10rem;
    background: var(--shade3-15);
    box-shadow: var(--highlight);
    overflow: hidden;
    transition: background var(--transition);

    &:hover {
      background: var(--shade3-25);

      svg {
        color: var(--shade1);
      }
    }

    svg {
      transition: color var(--transition);
    }

    .url {
      margin-inline-end: var(--sp-8);
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: var(--shade6); /* hack to set ellipses color */

      span {
        color: var(--shade4);
      }
      span.pathname {
        color: var(--shade2);
      }
    }
  }
`;

export const HistoryBtns = styled("div")`
  display: flex;
  align-items: center;
  -webkit-app-region: no-drag;
  isolation: isolate;
  margin-left: -0.5rem;

  button {
    all: unset;
    display: grid;
    place-content: center;
    width: var(--sp-8);
    height: var(--sp-8);
    cursor: pointer;
    pointer-events: all;
    color: var(--shade1-75);
    border-radius: var(--br-sm);

    &[disabled] {
      opacity: 0.4;
      pointer-events: none;
    }

    &:hover {
      color: var(--shade0);
    }

    svg {
      width: var(--sp-4_5);
      height: var(--sp-4_5);
      transition: color var(--transition);
    }
  }
`;
